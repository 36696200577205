import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { EpValidatorDirective } from './directive/ep-validator.directive';
import { FileDropDirective } from './directive/file-drop.directive';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { JwtModule } from '@auth0/angular-jwt';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';

import { DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeModule } from '@angular/material/tree';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { JoditAngularModule } from 'jodit-angular';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ButtonIconComponent } from './core/button-icon/button-icon.component';
import { ButtonComponent } from './core/button/button.component';
import { FormCheckboxComponent } from './core/form-checkbox/form-checkbox.component';
import { FormInputComponent } from './core/form-input/form-input.component';
import { FormTextareaComponent } from './core/form-textarea/form-textarea.component';
import { FormWYSIWYGComponent } from './core/form-wysiwyg/form-wysiwyg.component';
import { Heading1Component } from './core/heading1/heading1.component';
import { Heading2Component } from './core/heading2/heading2.component';
import { Heading3Component } from './core/heading3/heading3.component';
import { Heading4Component } from './core/heading4/heading4.component';
import { Heading5Component } from './core/heading5/heading5.component';
import { Heading6Component } from './core/heading6/heading6.component';
import { LinkButtonComponent } from './core/link-button/link-button.component';
import { SelectComponent } from './core/select/select.component';
import { TableComponent } from './core/table/table.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { JsonDataComponent } from './modals/json-data/json-data.component';
import { DepotFormComponent } from './pages/depots/depot-form/depot-form.component';
import { DepotsComponent } from './pages/depots/depots.component';
import { EmailFormComponent } from './pages/emails/email-form/email-form.component';
import { EmailsComponent } from './pages/emails/emails.component';
import { LogsComponent } from './pages/logs/logs.component';
import { PostFormComponent } from './pages/posts/post-form/post-form.component';
import { PostsComponent } from './pages/posts/posts.component';
import { ProofOfDeliveryComponent } from './pages/proof-of-delivery/proof-of-delivery.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SummariesComponent } from './pages/summaries/summaries.component';
import { UserFormComponent } from './pages/users/user-form/user-form.component';
import { UsersComponent } from './pages/users/users.component';
import { LoaderPartial } from './partials/loader/loader.component';
import { MenuComponent } from './partials/menu/menu.component';
import { NotificatorPartial } from './partials/notificator/notificator.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ShiftStatusesComponent } from './pages/shift-statuses/shift-statuses.component';
import { SummaryExportComponent } from './modals/summary-export/summary-export.component';
import { JobShiftOfferComponent } from './pages/job-shift-offer/job-shift-offer.component';
import { ModalBodyComponent } from './modals/modal-body/modal-body.component';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { LoaderV2Component } from './modals/loader-v2/loader-v2.component';
import { CustomOverlayComponent } from './modals/custom-overlay/custom-overlay.component';
import { SearchFilterPipe } from './core/pipe/search-filter.pipe';
import { ClientComponent } from './pages/client/client.component';
import { ClientFormComponent } from './pages/client/client-form/client-form.component';
import { ClientFormViewComponent } from './pages/client/client-form-view/client-form-view.component';
import { TimesheetComponent } from './pages/timesheet/timesheet.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { MAPBOX_ACCESSTOKEN } from './settings/settings';
import { LocationDataComponent } from './pages/location-data/location-data.component';
import { PopupComponent } from './modals/popup/popup.component';
import { NgxMaskModule } from 'ngx-mask';
import { Popupv2Component } from './core/popupv2/popupv2.component';
import { EditLocationsComponent } from './pages/settings/edit-locations/edit-locations.component';
import { EditDepartmentsComponent } from './pages/settings/edit-departments/edit-departments.component';
import { EditRolesComponent } from './pages/settings/edit-roles/edit-roles.component';
import { TableV2Component } from './core/table-v2/table-v2.component';
import { MultiselectAutocompleteComponent } from './core/multiselect-autocomplete/multiselect-autocomplete.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { EditAccessComponent } from './pages/settings/edit-access/edit-access.component';
import { EditInventoryComponent } from './pages/settings/edit-inventory/edit-inventory.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { OpenInNewTabDirective } from './directive/open-in-new-tab.directive';
import { FlexiJobOfferComponent } from './pages/job-shift-offer/flexi-job-offer/flexi-job-offer.component'
import { NotifierModule, NotifierOptions } from 'angular-notifier';

const customNotifierOptions: NotifierOptions = {
  position: {
		horizontal: {
			position: 'right',
			distance: 12
		},
		vertical: {
			position: 'bottom',
			distance: 12,
			gap: 10
		}
	},
  theme: 'material',
  behaviour: {
    autoHide: 500000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    AppComponent,
    FileDropDirective,
    EpValidatorDirective,
    LoginComponent,
    HomeComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ProfileEditComponent,

    FormInputComponent,
    FormTextareaComponent,
    FormWYSIWYGComponent,
    SelectComponent,
    LinkButtonComponent,
    ButtonComponent,
    ButtonIconComponent,
    Heading1Component,
    Heading2Component,
    Heading3Component,
    Heading4Component,
    Heading5Component,
    Heading6Component,
    TableComponent,

    LoaderPartial,
    NotificatorPartial,
    MenuComponent,

    UsersComponent,
    UserFormComponent,
    DepotsComponent,
    DepotFormComponent,
    ConfirmModalComponent,
    EmailsComponent,
    EmailFormComponent,
    LogsComponent,
    PostsComponent,
    PostFormComponent,
    JsonDataComponent,
    FormCheckboxComponent,
    SettingsComponent,
    SummariesComponent,
    ProofOfDeliveryComponent,
    SummaryExportComponent,
    ShiftStatusesComponent,
    JobShiftOfferComponent,
    ModalBodyComponent,
    LoaderV2Component,
    CustomOverlayComponent,
    SearchFilterPipe,
    ClientComponent,
    ClientFormComponent,
    ClientFormViewComponent,
    TimesheetComponent,
    LocationDataComponent,
    PopupComponent,
    Popupv2Component,
    EditLocationsComponent,
    EditDepartmentsComponent,
    EditRolesComponent,
    TableV2Component,
    MultiselectAutocompleteComponent,
    EditAccessComponent,
    EditInventoryComponent,
    OpenInNewTabDirective,
    FlexiJobOfferComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxMaterialTimepickerModule,
    FormsModule,
    AngularSvgIconModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem('access_token'),
        allowedDomains: [
          'laravel',
          '127.0.0.1:8000',
          'localhost:8000',
          'custm-api.2.jtbsyn.com',
          'staging.app.custm.com.au',
          'staging.devcustm.com.au',
          'preprod.devcustm.com.au',
          'app.custm.com.au',
        ],
      },
    }),
    MatDialogModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    JoditAngularModule,
    NgxJsonViewerModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatSidenavModule,
    MatTreeModule,
    MatIconModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    NgxCurrencyModule,
    NgxMapboxGLModule.withConfig({ accessToken: MAPBOX_ACCESSTOKEN }),
    NgxMaskModule.forRoot({ validation: false }),
    MatSelectModule,
    MatExpansionModule,
    MatChipsModule,
    MatIconModule,
    MatSlideToggleModule,
    NgxPermissionsModule.forRoot(),
    NgxSliderModule,
    NotifierModule.withConfig(customNotifierOptions)
  ],
  providers: [
    DatePipe,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatSidenavModule,
    MatTreeModule,
    MatIconModule,
    MatCheckboxModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function tokenGetter() {
  return localStorage.getItem('access_token');
}
