import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EpEncryptionService } from '../ep-encryption.service';
import { IndexQuery, buildParams } from 'src/app/model/IndexQuery';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { apiUrls } from 'src/app/settings/settings';
import { Timesheet } from 'src/app/model/custm/Timesheet';

@Injectable({
  providedIn: 'root',
})
export class TimesheetAPIService {
  constructor(
    private http: HttpClient,
    private epEncryptionService: EpEncryptionService
  ) {}

  public index(
    query: IndexQuery,
    startDate: string,
    endDate: string,
    location: string[],
    department: string[],
    clock_in_check: string[],
    clock_out_check: string[],
    authorisedAction: boolean[],
    commentsActions: boolean[],
    shiftDefinitions: string[],
    tolls: string[],
    regional: string[],
    paid: string[],
    checked: string[],
    orderArr: string[],
    saved_filter: boolean,
    is_export: boolean,
    is_payment: boolean,
    is_invoice: boolean
  ): Observable<CodedResponseModel> {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}timesheet${buildParams(query)}`,
      this.epEncryptionService.sendPayload({
        startDate,
        endDate,
        location,
        department,
        clock_in_check,
        clock_out_check,
        authorisedAction,
        commentsActions,
        shiftDefinitions,
        tolls,
        paid,
        checked,
        regional,
        orderArr,
        saved_filter,
        is_export,
        is_payment,
        is_invoice,
      })
    );
  }

  public seekInvoiceEligible(
    startDate: string,
    endDate: string
  ): Observable<CodedResponseModel> {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}timesheet/seek`,
      this.epEncryptionService.sendPayload({
        startDate,
        endDate,
      })
    );
  }

  public async bulkFetch(ids: number[]) {
    return this.http
      .post<CodedResponseModel>(
        `${apiUrls.apiUrl}timesheet/bulk`,
        this.epEncryptionService.sendPayload({ ids })
      )
      .toPromise();
  }

  public bulkUpdate(data: any): Observable<CodedResponseModel> {
    return this.http.put<CodedResponseModel>(
      `${apiUrls.apiUrl}timesheet/bulk/update`,
      this.epEncryptionService.sendPayload(data)
    );
  }

  public show(id: number): Observable<CodedResponseModel> {
    return this.http.get<CodedResponseModel>(`${apiUrls.apiUrl}client/${id}`);
  }

  public create(data: any): Observable<CodedResponseModel> {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}timesheet/create`,
      this.epEncryptionService.sendPayload(data)
    );
  }

  public edit(id: number, data: any): Observable<CodedResponseModel> {
    return this.http.put<CodedResponseModel>(
      `${apiUrls.apiUrl}timesheet/${id}`,
      this.epEncryptionService.sendPayload(data)
    );
  }

  public markAsRead(id: number): Observable<CodedResponseModel> {
    return this.http.put<CodedResponseModel>(
      `${apiUrls.apiUrl}timesheet/comments/${id}`,
      {}
    );
  }

  public delete(id: number): Observable<CodedResponseModel> {
    return this.http.delete<CodedResponseModel>(
      `${apiUrls.apiUrl}timesheet/${id}`
    );
  }

  public getDepartments(location?: string) {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}timesheet/departments?location=${location}`
    );
  }

  public getRoles(location?: string, department?: string) {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}timesheet/roles?location=${location}&department=${department}`
    );
  }

  public getBusinessDetails(): Observable<CodedResponseModel> {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}timesheet/business-details`
    );
  }

  public getDrivers(): Observable<CodedResponseModel> {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}timesheet/drivers`
    );
  }

  public updateField(data: any): Observable<CodedResponseModel> {
    return this.http.put<CodedResponseModel>(
      `${apiUrls.apiUrl}timesheet/update-column`,
      this.epEncryptionService.sendPayload(data)
    );
  }

  public saveFilters(data: any): Observable<CodedResponseModel> {
    return this.http.put<CodedResponseModel>(
      `${apiUrls.apiUrl}user/update/filters`,
      this.epEncryptionService.sendPayload(data)
    );
  }

  public processPayments(data: any) {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}timesheet/process-payments`,
      this.epEncryptionService.sendPayload(data)
    );
  }

  public getMissingManifests (): Observable<any> {
    return this.http.get(`${apiUrls.apiUrl}missing-manifests`);
  }
}
