import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuComponent } from './partials/menu/menu.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { LoginService } from './services/login.service';
import { NotifierService } from 'angular-notifier';
import { TimesheetAPIService } from './services/custm/timesheet.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'laravel8';
  public menuOpen: boolean = false;
  public audio = new Audio;
  private autoRefresher: any;
  private url!: any;
  constructor(
    private auth: LoginService,
    private permissionService: NgxPermissionsService,
    private notifier: NotifierService,
    private timesheetService: TimesheetAPIService,
    private router: Router
  ) {
    MenuComponent.show.subscribe((r) => {
      this.menuOpen = r;
    });

    const role: string = this.auth.getUserRole();
    const permissions: string[] = this.auth.getUserPermissions();
    this.permissionService.loadPermissions([role, ...permissions]);

    this.router.events.subscribe((route:any) => {
      if (route instanceof NavigationEnd) {
        this.url = route.url;
      }
    });
  }

  getMissingManifests () {
    if (this.url != '/login' && this.url !== undefined) {
      this.timesheetService.getMissingManifests().subscribe({
        next: (response: any) => {
          if (response?.missing_manifests_count > 0) {
            const audio = new Audio('assets/pop-alert.mp3');
            audio.play().then().catch(error => {});
            this.notifier.notify('info', `There are ${response.missing_manifests_count} new Flexi job offers available from clients`);
          }
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.autoRefresher = setInterval(() => {
      this.getMissingManifests();
    }, 300000);
  }

  ngOnDestroy(): void {
    clearInterval(this.autoRefresher);
  }
}
